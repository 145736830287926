import ChartDataLabels from 'chartjs-plugin-datalabels'
export const ReportsByCriticallity = {
  type: 'pie',
  plugins: [ChartDataLabels],
  data: {
    labels: [],
    datasets: [
      {
        label: 'Reports by Criticallity',
        data: [],
        backgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(54, 162, 235)',
          'rgb(255, 205, 86)',
          'rgb(50, 168, 82)',
          'rgb(234, 97, 255)',
        ],
        hoverOffset: 4,
      },
    ],
  },
  options: {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Reports by Criticallity',
      },
      legend: {
        position: 'right',
      },
      datalabels: {
        color: '#ffffff',
      },
    },
  },
}

export default ReportsByCriticallity
