<template>
  <div>
    <KTCodePreview
      ><template v-slot:preview
        ><b-row
          ><b-col lg="3" md="3"
            ><b-form-group label="Pabrik" label-for="pabrik_id"
              ><b-form-select
                v-model="selectedPabrik"
                :options="pabrik"
                value-field="id_pabrik"
                text-field="nama_pabrik"
                id="pabrik_id"
                @change="loadPlant"
              ></b-form-select></b-form-group></b-col
          ><b-col lg="3" md="3"
            ><b-form-group label="Plant" label-for="plant_id"
              ><b-form-select
                v-model="selectedPlant"
                :options="plant"
                value-field="id_plant"
                text-field="nama_plant"
                id="plant_id"
                @change="loadEquipment"
              ></b-form-select></b-form-group></b-col
          ><b-col lg="3" md="3"
            ><b-form-group label="Equipment" label-for="equipment_id"
              ><b-form-select
                v-model="selectedEquipment"
                :options="equipment"
                value-field="id_equipment"
                text-field="nomor_equipment"
                id="equipment_id"
              ></b-form-select></b-form-group></b-col
          ><b-col lg="3" md="3"
            ><b-form-group label="Tahun" label-for="year"
              ><b-form-select
                v-model="selectedYear"
                :options="years"
                value-field="year"
                text-field="year"
                id="year"
              ></b-form-select></b-form-group></b-col
        ></b-row>
        <b-row
          ><ReportEquipment
            :pabrik="selectedPabrik"
            :plant="selectedPlant"
            :year="selectedYear"
            :equipment="selectedEquipment"
          ></ReportEquipment></b-row></template
    ></KTCodePreview>
  </div>
</template>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue'
import ApiService from '@/core/services/api.service'
import ReportEquipment from '@/components/dashboard/Report.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'NewDashboard',
  components: {
    KTCodePreview,
    ReportEquipment,
  },
  computed: {
    ...mapGetters(['layoutConfig']),
    years() {
      const year = new Date().getFullYear()
      return Array.from({ length: year - 2019 }, (value, index) => 2020 + index)
    },
  },
  data() {
    return {
      selectedPabrik: 0,
      pabrik: [],
      selectedPlant: 0,
      plant: [],
      selectedYear: 2022,
      equipment: [],
      selectedEquipment: 0,
    }
  },
  created() {
    this.selectedYear = new Date().getFullYear()
    this.loadPabrik()
  },
  methods: {
    async loadPabrik() {
      await ApiService.get('/master/pabrik/all')
        .then(({ data }) => {
          if (data.status == 'ok') {
            this.pabrik = data.data
            this.selectedPabrik = this.pabrik[0].id_pabrik

            this.loadPlant()
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    async loadPlant() {
      await ApiService.get(
        '/master/plant/all?pabrik_id=' + this.selectedPabrik + '&sortBy=nama_plant&sortDesc=ASC'
      )
        .then(({ data }) => {
          if (data.status == 'ok') {
            this.plant = []
            this.plant.push({ id_plant: 0, nama_plant: 'All Plant' })
            this.plant = this.plant.concat(data.data.data)
            this.selectedPlant = this.plant[0].id_plant
            this.loadEquipment()
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    async loadEquipment() {
      await ApiService.get(
        '/master/equipment/getequipment?plant_id=' +
          this.selectedPlant +
          '&sortBy=nama_equipment&sortDesc=ASC'
      )
        .then(({ data }) => {
          this.equipment = []
          this.equipment.push({
            id_equipment: 0,
            nomor_equipment: 'All Equipment',
          })
          if (data.equipment.length > 0) {
            this.equipment = this.equipment.concat(data.equipment)
            this.selectedEquipment = this.equipment[0].id_equipment
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
  },
}
</script>
