<template>
  <b-container>
    <b-row align-v="center">
      <b-col md="4" v-show="equipment == 0">
        <canvas id="report-dashboard-risk"></canvas>
      </b-col>
      <b-col md="4" v-show="equipment != 0">
        <div class="d-flex justify-content-center"><h3>Risk</h3></div>
        <table class="h3 justify-content-center">
          <tr>
            <td>(P)</td>
            <td>&nbsp;</td>
            <td>(C)</td>
            <td>&nbsp;&nbsp;&nbsp;</td>
            <td>&nbsp;&nbsp;&nbsp;</td>
          </tr>
          <tr align="center">
            <td>{{ probability }}</td>
            <td>x</td>
            <td>{{ konsekuensi }}</td>
            <td>=</td>
            <td>&nbsp;&nbsp;{{ probability * konsekuensi }}</td>
          </tr>
        </table>
      </b-col>
      <b-col md="4">
        <canvas id="report-dashboard-equipment" v-show="equipment == 0"></canvas>
        <canvas id="report-dashboard-part" v-show="equipment != 0"></canvas>
      </b-col>
      <div class="col-md-4"><canvas id="report-dashboard-status"></canvas></div>
    </b-row>
    <b-row>&nbsp;</b-row>
    <b-row>
      <b-col md="4" v-show="equipment == 0">
        <canvas id="report-dashboard-equipment-type"></canvas>
      </b-col>
      <b-col md="4" v-if="equipment != 0">
        <div class="d-flex justify-content-center"><h3>Data</h3></div>
        <table class="table" v-show="equipmentDetail.length > 0">
          <tr>
            <td>Nama Equipment</td>
            <td>:</td>
            <td>
              {{ equipmentDetail.length > 0 ? equipmentDetail[0].nama_equipment : '-' }}
            </td>
          </tr>
          <tr>
            <td>Jenis Equipment</td>
            <td>:</td>
            <td>
              {{ equipmentDetail.length > 0 ? equipmentDetail[0].nama_jenis : '-' }}
            </td>
          </tr>
          <tr>
            <td>Manufacturer</td>
            <td>:</td>
            <td>
              {{ equipmentDetail.length > 0 ? equipmentDetail[0].manufacturer : '-' }}
            </td>
          </tr>
          <tr>
            <td>Penggantian Ke</td>
            <td>:</td>
            <td>
              {{ equipmentDetail.length > 0 ? equipmentDetail[0].penggantian_ke : '-' }}
            </td>
          </tr>
          <tr>
            <td>Penggantian Terakhir</td>
            <td>:</td>
            <td>
              {{ equipmentDetail.length > 0 ? equipmentDetail[0].penggantian_terakhir : '-' }}
            </td>
          </tr>
        </table>
      </b-col>
      <b-col md="4">
        <canvas id="report-dashboard-month"></canvas>
      </b-col>
      <b-col md="4">
        <canvas id="report-dashboard-kritis"></canvas>
      </b-col>
    </b-row>
    <b-row>&nbsp;</b-row>
    <b-row>
      <div class="col-md-12">
        <strong>Report &amp; Record</strong>
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th>Tanggal</th>
              <th>Equipment</th>
              <th>Part</th>
              <th>Hasil</th>
              <th>Kekritisan</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(r, index) in lastreports" :key="r.id_report" @click="viewReport(index)">
              <td>{{ r.date }}</td>
              <td>{{ r.nomor_equipment }}</td>
              <td>{{ r.nama_part }}</td>
              <td v-html="r.inspection_result"></td>
              <td>{{ r.critically }}</td>
              <td>{{ r.status }}</td>
            </tr>
            <tr>
              <td style="text-align: center" colspan="6" v-if="lastreports.length == 0">
                Tidak ada data
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-row>
    <b-row>&nbsp;</b-row>
    <b-row>
      <div class="col-md-12">
        <strong>Data Desain</strong>
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th>Tanggal</th>
              <th>Equipment</th>
              <th>Nomor Dokumen</th>
              <th>Jenis Dokumen</th>
              <th>Versi Dokumen</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(r, index) in datadesain" :key="r.id_data_desain" @click="dataDetail(index)">
              <td>{{ r.tanggal }}</td>
              <td>{{ r.nama_equipment }}</td>
              <td>{{ r.nomor_dokumen }}</td>
              <td>{{ r.jenis_dokumen }}</td>
              <td>{{ r.versi_dokumen }}</td>
            </tr>
            <tr>
              <td style="text-align: center" colspan="5" v-if="datadesain.length == 0">
                Tidak ada data
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-row>
    <b-modal
      id="info-modal"
      size="xl"
      :title="`${modalInfo.nomor_equipment} - ${modalInfo.nama_part}`"
    >
      <b-row>
        <b-col>
          <b-form-group
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="Date"
            label-for="date"
          >
            <b-form-input
              id="date"
              size="sm"
              v-model="modalInfo.date"
              disabled
            ></b-form-input> </b-form-group
          ><b-form-group
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="No Report"
            label-for="no_report"
          >
            <b-form-input
              id="no_report"
              size="sm"
              v-model="modalInfo.no_report"
              disabled
            ></b-form-input> </b-form-group
          ><b-row class="mb-2" v-if="modalInfo.report_type == 'report'">
            <b-col sm="5" class="text-sm-left">Issued to</b-col>
            <b-col>
              <ul>
                <li v-for="(item, index) in JSON.parse(modalInfo.issued_to)" :key="index">
                  {{ JSON.parse(item).label }}
                </li>
              </ul>
            </b-col> </b-row
          ><b-form-group
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="Nama Pabrik"
            label-for="nama_pabrik"
          >
            <b-form-input
              id="nama_pabrik"
              size="sm"
              v-model="modalInfo.nama_pabrik"
              disabled
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="Nama Plant"
            label-for="nama_plant"
          >
            <b-form-input
              id="nama_plant"
              size="sm"
              v-model="modalInfo.nama_plant"
              disabled
            ></b-form-input> </b-form-group
          ><b-form-group
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="Nama Equipment"
            label-for="nama_equipment"
          >
            <b-form-input
              id="nama_equipment"
              size="sm"
              v-model="modalInfo.nama_equipment"
              disabled
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="Nama Part"
            label-for="nama_part"
          >
            <b-form-input
              id="nama_part"
              size="sm"
              v-model="modalInfo.nama_part"
              disabled
            ></b-form-input> </b-form-group
          ><b-form-group
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="Method"
            label-for="method"
          >
            <b-form-input id="method" size="sm" v-model="modalInfo.method" disabled></b-form-input>
          </b-form-group>
          <b-form-group
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="Inspector"
            label-for="inspector"
          >
            <b-form-input
              id="inspector"
              size="sm"
              v-model="modalInfo.inspector_name"
              disabled
            ></b-form-input> </b-form-group
          ><b-row class="mb-2" v-if="modalInfo.report_type == 'report'">
            <b-col sm="5" class="text-sm-left">Co-Inspector</b-col>
            <b-col>
              <ul>
                <li v-for="(item, index) in JSON.parse(modalInfo.co_inspector)" :key="index">
                  {{ JSON.parse(item).label }}
                </li>
              </ul>
            </b-col>
          </b-row></b-col
        ><b-col
          ><b-form-group
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="Hasil Pemeriksaan"
            label-for="inspection_result"
          >
            <vue-editor
              id="inspection_result"
              :editorToolbar="customToolbar"
              v-model="modalInfo.inspection_result"
              :disabled="true"
            ></vue-editor> </b-form-group
          ><b-form-group
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="Criticallity"
            label-for="criticallity "
          >
            <b-form-input
              id="criticallity"
              size="sm"
              v-model="modalInfo.criticallity"
              disabled
            ></b-form-input>
          </b-form-group>
          <b-form-group
            v-if="modalInfo.report_type == 'record'"
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="Jenis Record"
            label-for="jenis_record "
          >
            <b-form-input
              id="jenis_record"
              size="sm"
              v-model="modalInfo.jenis_record"
              disabled
            ></b-form-input> </b-form-group></b-col
        ><b-col
          ><b-form-group
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="Rekomendasi "
            label-for="rekomendasi "
          >
            <vue-editor
              id="rekomendasi "
              :editorToolbar="customToolbar"
              v-model="modalInfo.recomendation"
              :disabled="true"
            ></vue-editor> </b-form-group
          ><b-form-group
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="Priority"
            label-for="priority "
          >
            <b-form-input
              id="priority"
              size="sm"
              v-model="modalInfo.priority"
              disabled
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="Repair Taken"
            label-for="repair_taken"
          >
            <vue-editor
              id="repair_taken"
              :editorToolbar="customToolbar"
              v-model="modalInfo.repair_taken"
              :disabled="true"
            ></vue-editor>
          </b-form-group>
          <b-form-group
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="Status"
            label-for="status"
          >
            <b-form-input id="status" size="sm" v-model="modalInfo.status" disabled></b-form-input>
          </b-form-group>
          <b-form-group
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="Future Recomendation"
            label-for="future_recomendation"
          >
            <vue-editor
              id="future_recomendation"
              :editorToolbar="customToolbar"
              v-model="modalInfo.future_recomendation"
              :disabled="true"
            ></vue-editor>
          </b-form-group>
          <b-row class="mb-2" v-if="modalInfo.report_type == 'report'">
            <b-col sm="5" class="text-sm-left">File</b-col>
            <b-col>
              <b-button
                v-if="modalInfo.file"
                variant="outline-info"
                size="sm"
                :href="urlStorage + '/file/report/' + modalInfo.file"
                target="_blank"
              >
                <span class="svg-icon svg-icon-sm svg-icon-info">
                  <i class="flaticon-eye text-success"></i>
                </span>
                Lihat File
              </b-button>
              <span v-else>Tidak ada file.</span>
            </b-col>
          </b-row>
          <b-form-group
            v-if="modalInfo.report_type == 'record'"
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="Deskripsi"
            label-for="deskripsi"
          >
            <vue-editor
              id="deskripsi"
              :editorToolbar="customToolbar"
              v-model="modalInfo.description"
              :disabled="true"
            ></vue-editor> </b-form-group></b-col></b-row
      ><b-row class="mb-2">
        <b-col md="6" offset-md="3">
          <b-carousel
            v-if="modalInfo.image"
            id="carousel-1"
            v-model="slide"
            :interval="4000"
            controls
            indicators
            background="#ababab"
            img-width="1024"
            img-height="480"
            style="text-shadow: 1px 1px 2px #333"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
          >
            <!-- Text slides with image -->
            <b-carousel-slide
              v-for="(img, index) in JSON.parse(modalInfo.image)"
              :key="index"
              :img-src="urlStorage + '/images/report/' + img"
              :text="modalInfo.image_keterangan[index]"
            ></b-carousel-slide>
          </b-carousel>
          <span v-else> Tidak ada gambar. </span>
        </b-col>
      </b-row></b-modal
    >
    <b-modal
      id="data-design-modal"
      size="xl"
      :title="`${modalInfo.nomor_equipment} - ${modalInfo.jenis_dokumen}`"
    >
      <b-row>
        <b-col>
          <b-form-group
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="Pabrik"
            label-for="pabrik"
          >
            <b-form-input
              id="pabrik"
              size="sm"
              v-model="modalInfo.nama_pabrik"
              disabled
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="Nama Plant"
            label-for="pabrik"
          >
            <b-form-input
              id="pabrik"
              size="sm"
              v-model="modalInfo.nama_plant"
              disabled
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="Equipment"
            label-for="equipment"
          >
            <b-form-input
              id="equipment"
              size="sm"
              v-model="modalInfo.equipment"
              disabled
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="Tanggal"
            label-for="tanggal"
          >
            <b-form-input
              id="tanggal"
              size="sm"
              v-model="modalInfo.tanggal"
              disabled
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="Nomor Dokumen"
            label-for="nomor_dokumen"
          >
            <b-form-input
              id="nomor_dokumen"
              size="sm"
              v-model="modalInfo.nomor_dokumen"
              disabled
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="Jenis Dokumen"
            label-for="jenis_dokumen"
          >
            <b-form-input
              id="jenis_dokumen"
              size="sm"
              v-model="modalInfo.jenis_dokumen"
              disabled
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="Versi Dokumen"
            label-for="versi_dokumen"
          >
            <b-form-input
              id="versi_dokumen"
              size="sm"
              v-model="modalInfo.versi_dokumen"
              disabled
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mx-1 my-3">
        <div style="height: 400px" v-if="modalInfo.isPdf">
          <pdf :src="urlStorage + '/file/data_desain/' + modalInfo.file" style="width: 100%"></pdf>
        </div>
        <b-col v-else md="6" offset-md="3">
          <b-img
            :src="urlStorage + '/file/data_desain/' + modalInfo.file"
            fluid
            style="width: 100%"
          ></b-img>
        </b-col>
      </b-row>
    </b-modal>
  </b-container>
</template>
<script>
import ApiService from '@/core/services/api.service'
import Chart from 'chart.js/auto'
import { VueEditor } from 'vue2-editor'
import ReportsByEquipment from './reports-equipment'
import ReportsPerStatus from './reports-status'
import ReportsRisk from './reports-risk'
import ReportsEquipmentType from './reports-equipment-type'
import ReportsPerMonth from './reports-per-month'
import ReportsByCriticallity from './reports-kritis'
import ReportsByPart from './reports-part'
import pdf from 'vue-pdf'
//import * as moment from "moment";
export default {
  name: 'ReportEquipment',
  props: {
    pabrik: Number,
    plant: Number,
    equipment: Number,
    year: Number,
  },
  data() {
    return {
      slide: 0,
      urlStorage: '',
      customToolbar: [[]],
      cReportsByEquipment: '',
      cReportsPerStatus: '',
      cReportsRisk: '',
      cReportsEquipmentType: '',
      cReportsPerMonth: '',
      cReportsByCriticallity: '',
      cReportsByPart: '',
      reportsByEquipment: ReportsByEquipment,
      reportsPerStatus: ReportsPerStatus,
      reportsRisk: ReportsRisk,
      reportsEquipmentType: ReportsEquipmentType,
      reportsPerMonth: ReportsPerMonth,
      reportsByCriticallity: ReportsByCriticallity,
      reportsByPart: ReportsByPart,
      lastreports: [],
      datadesain: [],
      probability: 0,
      konsekuensi: 0,
      equipmentDetail: [],
      modalInfo: {},
    }
  },
  components: { VueEditor, pdf },
  created() {
    this.urlStorage = ApiService.urlStorage()
  },
  mounted() {
    const ctx = document.getElementById('report-dashboard-equipment')
    this.cReportsByEquipment = new Chart(ctx, this.reportsByEquipment)

    const ctxStatus = document.getElementById('report-dashboard-status')
    this.cReportsPerStatus = new Chart(ctxStatus, this.reportsPerStatus)

    const ctxRisk = document.getElementById('report-dashboard-risk')
    this.cReportsRisk = new Chart(ctxRisk, this.reportsRisk)

    const ctxEquipmentType = document.getElementById('report-dashboard-equipment-type')
    this.cReportsEquipmentType = new Chart(ctxEquipmentType, this.reportsEquipmentType)

    const ctxMonth = document.getElementById('report-dashboard-month')
    this.cReportsPerMonth = new Chart(ctxMonth, this.reportsPerMonth)

    const ctxKritis = document.getElementById('report-dashboard-kritis')
    this.cReportsByCriticallity = new Chart(ctxKritis, this.reportsByCriticallity)

    const ctxPart = document.getElementById('report-dashboard-part')
    this.cReportsByPart = new Chart(ctxPart, this.reportsByPart)
  },
  methods: {
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    },
    viewReport(index) {
      if (this.lastreports[index].method == 'null') {
        this.lastreports[index].method = '-'
      }
      if (this.lastreports[index].status == 'null') {
        this.lastreports[index].status = '-'
      }
      if (this.lastreports[index].priority == 'null') {
        this.lastreports[index].priority = '-'
      }
      this.modalInfo.image_keterangan = JSON.parse(this.lastreports[index].image_keterangan)
      this.modalInfo = this.lastreports[index]
      this.$root.$emit('bv::show::modal', 'info-modal')
    },
    reportDetail(id) {
      this.$router.push({ name: 'Edit Report', params: { id: id } })
    },
    dataDetail(index) {
      this.modalInfo = this.datadesain[index]
      if (this.modalInfo.file.includes('.pdf')) {
        this.modalInfo.isPdf = true
      } else {
        this.modalInfo.isPdf = false
      }
      //this.modalInfo.tanggal = moment(this.modalInfo.tanggal);
      this.modalInfo.equipment =
        this.modalInfo.nama_equipment + ' (' + this.modalInfo.nomor_equipment + ')'
      this.$root.$emit('bv::show::modal', 'data-design-modal')
    },
    async loadData() {
      await ApiService.get(
        '/dashboard/getChartData?pabrik=' +
          this.pabrik +
          '&plant=' +
          this.plant +
          '&equipment=' +
          this.equipment +
          '&tahun=' +
          this.year
      )
        .then(({ data }) => {
          this.equipmentDetail = data.equipmentDetail
          if (this.equipmentDetail.length > 0) console.log(this.equipmentDetail[0].nama_equipment)
          if (data.riskEquipment.length > 0) {
            this.probability = data.riskEquipment[0].probability
            this.konsekuensi = data.riskEquipment[0].konsekuensi
          }
          this.lastreports = data.lastreport
          this.datadesain = data.data_desain
          this.cReportsByEquipment.data.labels = []
          this.cReportsByEquipment.data.datasets.forEach(dataset => {
            dataset.data = []
          })

          this.cReportsPerStatus.data.labels = []
          this.cReportsPerStatus.data.datasets.forEach(dataset => {
            dataset.data = []
          })

          this.cReportsRisk.data.labels = []
          this.cReportsRisk.data.datasets.forEach(dataset => {
            dataset.data = []
          })

          this.cReportsEquipmentType.data.labels = []
          this.cReportsEquipmentType.data.datasets.forEach(dataset => {
            dataset.data = []
          })

          this.cReportsPerMonth.data.labels = []
          this.cReportsPerMonth.data.datasets.forEach(dataset => {
            dataset.data = []
          })

          this.cReportsByCriticallity.data.labels = []
          this.cReportsByCriticallity.data.datasets.forEach(dataset => {
            dataset.data = []
          })

          this.cReportsByPart.data.labels = []
          this.cReportsByPart.data.datasets.forEach(dataset => {
            dataset.data = []
          })

          for (const item of data.equipment) {
            this.cReportsByEquipment.data.labels.push(item.nomor_equipment)
            this.cReportsByEquipment.data.datasets.forEach(dataset => {
              dataset.data.push(item.total)
            })
          }
          this.cReportsByEquipment.update()
          var totalStatus = 0
          for (const item of data.status) {
            this.cReportsPerStatus.data.labels.push(item.status)
            this.cReportsPerStatus.data.datasets.forEach(dataset => {
              dataset.data.push(item.jumlah)
              totalStatus = totalStatus + item.jumlah
            })
          }
          this.cReportsPerStatus.options.plugins.title.text = [
            'Reports per Status',
            'Total ' + totalStatus,
          ]
          this.cReportsPerStatus.update()

          for (const item of data.risk) {
            this.cReportsRisk.data.labels.push(item.nomor_equipment)
            this.cReportsRisk.data.datasets.forEach(dataset => {
              dataset.data.push(item.risk)
            })
          }
          this.cReportsRisk.update()
          var totalType = 0
          for (const item of data.equipmentType) {
            this.cReportsEquipmentType.data.labels.push(item.nama_jenis)
            this.cReportsEquipmentType.data.datasets.forEach(dataset => {
              dataset.data.push(item.total)
              totalType = totalType + item.total
            })
          }
          this.cReportsEquipmentType.options.plugins.title.text = [
            'Equipment by Type',
            'Total ' + totalStatus,
          ]
          this.cReportsEquipmentType.update()

          for (const item of data.reportMonth) {
            this.cReportsPerMonth.data.labels.push(item.bulan)
            this.cReportsPerMonth.data.datasets.forEach(dataset => {
              dataset.data.push(item.jumlah)
            })
          }
          this.cReportsPerMonth.update()
          var totalKritis = 0
          for (const item of data.reportKritis) {
            this.cReportsByCriticallity.data.labels.push(item.critically)
            this.cReportsByCriticallity.data.datasets.forEach(dataset => {
              dataset.data.push(item.total)
              totalKritis = totalKritis + item.total
            })
          }
          this.cReportsByCriticallity.options.plugins.title.text = [
            'Reports by Criticallity',
            'Total ' + totalStatus,
          ]
          this.cReportsByCriticallity.update()

          for (const item of data.reportPart) {
            this.cReportsByPart.data.labels.push(item.nama_part)
            this.cReportsByPart.data.datasets.forEach(dataset => {
              dataset.data.push(item.total)
            })
          }
          this.cReportsByPart.update()
        })
        .catch(response => {
          console.log(response)
        })
    },
  },
  watch: {
    pabrik: function() {
      this.loadData()
    },
    plant: function() {
      this.loadData()
    },
    equipment: function() {
      this.loadData()
    },
    year: function() {
      this.loadData()
    },
  },
}
</script>
