export const ReportsRisk = {
  type: 'bar',
  data: {
    labels: [],
    datasets: [
      {
        label: 'Risk',
        data: [],
        backgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(54, 162, 235)',
          'rgb(255, 205, 86)',
          'rgb(50, 168, 82)',
          'rgb(234, 97, 255)',
        ],
        hoverOffset: 4,
      },
    ],
  },
  options: {
    responsive: true,
    plugins: {},
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          userCallback: function(label) {
            // when the floored value is the same as the value we have a whole number
            if (Math.floor(label) === label) {
              return label
            }
          },
        },
      },
    },
  },
}

export default ReportsRisk
